import React from "react";

import Section from "./Section";

const Home = () => {
  return (
    <React.Fragment>
      <Section />
    </React.Fragment>
  );
};

export default Home;
