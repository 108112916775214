import React from 'react'
import { Link } from 'react-router-dom'
import logoDark from "../assets/img/logo_dark.png";
import { Container } from 'react-bootstrap';

const Nonauthlayout = ({children}:any) => {
  return (
   <React.Fragment>
    <nav className={"navbar navbar-expand-lg fixed-top sticky nav-sticky"} id="navbar">
      <Container>
        <Link className="navbar-brand logo" to="/">
          <img src={logoDark} alt="" className="logo-dark" height="45" />
        </Link>
      </Container>
    </nav>
    {children}
   </React.Fragment>
  )
}

export default Nonauthlayout