import React from "react";
import { Container, Row, Col, Card, Stack } from "react-bootstrap";
import whatWeDoImg from "../assets/img/section_bottom.png";
import iconGame from "../assets/img/icon_full_game.png";
import iconGameTesting from "../assets/img/icon_game_testing.png";
import iconDev from "../assets/img/icon_co-dev.png";
import iconOperations from "../assets/img/icon_live_ops.png";

const features = [
  {
    id: 1,
    title: "Full Game Development",
    icon: iconGame,
  },
  {
    id: 2,
    title: "CO-Development",
    icon: iconDev,
  },
  {
    id: 3,
    title: "Game Testing",
    icon: iconGameTesting,
  },
  {
    id: 4,
    title: "Live Operations",
    icon: iconOperations,
  },
];
const Services = () => {
  const innerWidth = window.innerWidth;
  console.log(innerWidth, "innerWidth");
  const mt = innerWidth > 2000 ? 900 : innerWidth > 1200 ? 900 : innerWidth > 900 ? 900 : innerWidth > 768 ? 900 : 900;
  return (
    <React.Fragment>
      <section className="section mt-5" id="services">
        <Container>
          <Row
            className="justify-content-center bg-image d-flex align-items-center"
            style={{
              backgroundImage: `url(${whatWeDoImg})`,
              height: mt,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
            }}
          >
            <Col lg={9} md={10} sm={11} xs={11} className="text-center">
              <div className="text-title  title mb-0 mb-lg-3 pt-sm-5 pt-0">
                <p>
                  We have been building Mobile games for over 15 years. We have different models and ways of working with our partners and are open to
                  defining the best model that works for each partner
                </p>
              </div>
              <Stack direction="horizontal" className=" mx-auto features">
                {features.map((item: any, index: number) => (
                  <Card className="features-item" key={index}>
                    <Card.Img variant="top" src={item.icon} className="img-fluid" />
                    <Card.Title>{item.title}</Card.Title>
                  </Card>
                ))}
              </Stack>

              <div className="text-title title  mt-lg-5 mt-md-3 mt-sm-0 mt-0">
                <p>
                  <span className="text-strong">New Game Development:</span> Build a new game going through various stages validating each stage by
                  KPI: Alpha Build, Beta Builds, Soft Launches, until Global Launch
                </p>
                <p>
                  <span className="text-strong">Full Game Development and Take over of an existing game:</span> Take over the feature development and
                  live operations of a game either in production or already live. Our teams are familiar with going through quick and seamless
                  transitions
                </p>
                <p>
                  <span className="text-strong">Live Operations:</span> Focus exclusively on the Live Operations of the game pushing out events, sales
                  and monitoring game revenue and KPI
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Services;
