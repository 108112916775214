import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import daNang from "../assets/img/studio_danang.png";
import hcm from "../assets/img/studio_hcm.png";
import milan from "../assets/img/studio_milan.png";
import haNoi from "../assets/img/studio_hanoi.png";
const AboutUs = () => {
  return (
    <React.Fragment>
      <section className="section" id="about">
        <Container>
          <Row className="justify-content-center">
            <Col lg={8}>
              <div className="text-center title mb-lg-5 mb-sm-5 mb-0">
                <h1 className="mb-3">ABOUT US</h1>
              </div>
            </Col>
          </Row>

          <Row className="align-items-center">
            <Col lg={{ order: "first", span: 6 }} sm={8} xs={{ order: "last" }}>
              <div className="features-img mt-4">
                <Image src={haNoi} alt="" fluid className="mx-auto d-block" />
              </div>
            </Col>

            <Col lg={6} ms={{ lg: "auto" }}>
              <div className="mt-5 mt-lg-4">
                <h3>Hanoi Studio</h3>
                <h4>Opened in 2006, 270+ members</h4>
                <p className="mt-3">
                  Hanoi is the capital of VietNam, located in the north of the country, famous for its bustling streets and narrow alleys.
                </p>
                <p className="mt-3">
                  We have multiple teams doing full-game development as well as Live Ops and QA work. The teams in Hanoi work on over 10 different
                  games in parallel with over 270 employees.
                </p>
              </div>
            </Col>
          </Row>

          <Row className="align-items-center">
            <Col lg={6} order={{ sm: "last" }}>
              <div className="mt-4 mt-lg-0">
                <h3>Danang Studio</h3>
                <h4>Opened in 2018, 100+ members</h4>
                <p>
                  Danang is known as the new Technical Hub of VietNam, located in the center of the country, famous for its beaches and dragon bridge.
                </p>
              </div>
            </Col>

            <Col lg={6} ms={{ lg: "auto" }} sm={8} order={{ sm: "first", lg: "last" }}>
              <div>
                <Image src={daNang} alt="" fluid className="mx-auto d-block" />
              </div>
            </Col>
          </Row>

          <Row className="align-items-center">
            <Col lg={{ order: "first", span: 6 }} sm={8} xs={{ order: "last" }}>
              <div className="features-img mt-4">
                <Image src={hcm} alt="" fluid className="mx-auto d-block" />
              </div>
            </Col>

            <Col lg={6} ms={{ lg: "auto" }}>
              <div className="mt-5 mt-lg-4">
                <h3>Ho Chi Minh City Studio</h3>
                <h4>Opened in 2022, 40+ members</h4>
                <p className="mt-3">
                  Ho Chi Minh City, often referred to as the bustling Economic Capital of Viet Nam, is situated in the southern region, renowned for
                  its dynamic atmosphere, historical landmarks.
                </p>
              </div>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col lg={6} order={{ sm: "last" }}>
              <div className="mt-4 mt-lg-0">
                <h3>Milan Studio</h3>
                <h4>Open in 2023, 10+ members</h4>
                <p>
                  Milan, Italy's fashion and financial heart, is known for high-end shops, restaurants, and the iconic Duomo Cathedral. Located in the
                  north.
                </p>
              </div>
            </Col>

            <Col lg={6} ms={{ lg: "auto" }} sm={8} order={{ sm: "first", lg: "last" }}>
              <div>
                <Image src={milan} alt="" fluid className="mx-auto d-block" />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default AboutUs;
