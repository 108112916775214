import React from 'react';
import Routing from './Routes';

import './assets/scss/style.scss'

function App() {
  return (
    <React.Fragment>
      <Routing />
    </React.Fragment>
  );
}

export default App;
