import React, { useEffect, useState } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";

import { Link } from "react-router-dom";
import Scrollspy from "react-scrollspy";

// // import images
import logoLight from "../assets/img/logo_light.png";
import logoDark from "../assets/img/logo_dark.png";
function useLocationHash() {
  const [hash, setHash] = useState(window.location.hash);
  window.addEventListener("hashchange", () => setHash(window.location.hash));
  return hash;
}
function getWindowHash() {
  return window.location.hash.replace("#", "");
}
const Navbars = ({ isDark }: any) => {
  const [isOpenMenu, setisOpenMenu] = useState<boolean>(false);
  const [navClass, setnavClass] = useState<string>("nav-sticky");
  const [activeMenu, setActiveMenu] = useState("home");
  const toggle = () => setisOpenMenu(!isOpenMenu);

  const isDarkClass = isDark ? "navbar-light" : "";

  const [hash, setWindowHash] = useState(getWindowHash());
  useEffect(() => {
    function handleWindowHash() {
      setWindowHash(getWindowHash());
    }

    window.addEventListener("hashchange", handleWindowHash);

    return () => {
      window.removeEventListener("hashchange", handleWindowHash);
    };
  }, []);

  useEffect(() => {
    if (hash !== "") {
      setActiveMenu(hash.replace("#", ""));
    }
  }, [hash]);

  return (
    <React.Fragment>
      <nav className={"navbar navbar-expand-lg fixed-top " + isDarkClass + " sticky " + navClass} id="navbar">
        <Container>
          <Link className="navbar-brand logo" to="#" onClick={() => window.scrollTo(0, 0)}>
            <img src={logoLight} alt="" className="logo-light" height="45" />
            <img src={logoDark} alt="" className="logo-dark" height="45" />
          </Link>
          <Navbar.Toggle
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded={isOpenMenu}
            aria-label="Toggle navigation"
            onClick={toggle}
          >
            <span className="navbar-toggler-icon"></span>
          </Navbar.Toggle>
          <Navbar.Collapse
            className="collapse navbar-collapse"
            id="navbarSupportedContent"
            in={isOpenMenu}
            onClick={() => {
              if (isOpenMenu) {
                setisOpenMenu(false);
              }
            }}
          >
            <Scrollspy
              offset={-18}
              items={["home", "games", "about", "join-us", "contact"]}
              currentClassName="active"
              delayMs={500}
              className="navbar-nav ms-auto mb-2 mb-lg-0"
              id="mySidenav"
            >
              <li className="nav-item">
                <Nav.Link href="#home" className={`nav-link ${activeMenu === "home" ? "active" : ""}`} id="scrollElement">
                  Home
                </Nav.Link>
              </li>
              <li className="nav-item">
                <Nav.Link href="#games" className={`nav-link ${activeMenu === "games" ? "active" : ""}`}>
                  Games
                </Nav.Link>
              </li>
              <li className="nav-item">
                <Nav.Link href="#about" className={`nav-link ${activeMenu === "about" ? "active" : ""}`}>
                  About Us
                </Nav.Link>
              </li>
              <li className="nav-item">
                <Nav.Link href="#services" className={`nav-link ${activeMenu === "services" ? "active" : ""}`}>
                  Services
                </Nav.Link>
              </li>

              <li className="nav-item">
                <Nav.Link href="#partners" className={`nav-link ${activeMenu === "partners" ? "active" : ""}`}>
                  Partners
                </Nav.Link>
              </li>
              <li className="nav-item">
                <Nav.Link href="#contact" className={`nav-link ${activeMenu === "contact" ? "active" : ""}`}>
                  Contact Us
                </Nav.Link>
              </li>
            </Scrollspy>
          </Navbar.Collapse>
        </Container>
      </nav>
    </React.Fragment>
  );
};

export default Navbars;
