import React, { useEffect, useState } from "react";
import { Container, Image, Stack } from "react-bootstrap";
import client1 from "../assets/img/partner_riot.png";
import client2 from "../assets/img/partner_scopely.png";
import client3 from "../assets/img/partner_rovio.png";
import client4 from "../assets/img/partner_playstudios.png";
import client5 from "../assets/img/partner_2k.png";
import client6 from "../assets/img/partner_tiltingpoint.png";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
const partnerImg = [
  {
    id: 1,
    image: client1,
  },
  {
    id: 2,
    image: client2,
  },
  {
    id: 3,
    image: client3,
  },
  {
    id: 4,
    image: client4,
  },
  {
    id: 5,
    image: client5,
  },
  {
    id: 6,
    image: client6,
  },
];

function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}
const Partners = () => {
  const [windowSize, setWindowSize] = useState(getWindowSize());
  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  return (
    <React.Fragment>
      <section className="section mt-5" id="partners">
        <Container>
          <div className="text-center title mb-5">
            <h1 className="mb-3 text-bold">OUR PARTNERS</h1>
            <p className="text-muted fw-normal mb-2 text-description">We are proud to have partnered with the Best!</p>
          </div>
          <Stack direction="horizontal" className="mt-5 mb-5 justify-content-center">
            <Swiper
              breakpoints={{
                320: {
                  slidesPerView: 1,
                },
                768: {
                  slidesPerView: 3,
                },
                992: {
                  slidesPerView: 6,
                },
                1200: {
                  slidesPerView: 6,
                },
              }}
              pagination={{
                clickable: true,
              }}
              modules={[Pagination]}
              className="partnerSwiper"
            >
              {partnerImg.map((item: any, key: any) =>
                windowSize.innerWidth < 768 ? (
                  <SwiperSlide key={key} className="d-flex justify-content-center  align-items-center mt-1 mb-5">
                    <div className="mx-auto">
                      <Image src={item.image} alt="" />
                    </div>
                  </SwiperSlide>
                ) : (
                  <SwiperSlide key={key} className="partners-slide">
                    <Image src={item.image} alt="partners-img" className="partners-img d-block mb-5" />
                  </SwiperSlide>
                )
              )}
            </Swiper>
          </Stack>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Partners;
