import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import playImg from "../assets/img/section_mid.png";

const Play = () => {
  return (
    <React.Fragment>
      <section className="section" id="play">
        <Container>
          <Row className="justify-content-center align-items-center">
            <Col lg={12}>
              <Image src={playImg} alt="" className="img-fluid" />
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Play;
