import React from "react";

import Section from "./Section";

const PrivacyPolicy = () => {
  return (
    <React.Fragment>
      <Section />
    </React.Fragment>
  );
};

export default PrivacyPolicy;
