import React from "react";
import { Container, Row, Image } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Pagination, Autoplay, EffectFade } from "swiper/modules";
import banner1 from "../../assets/img/banner1.png";
import banner2 from "../../assets/img/banner2.png";
import banner3 from "../../assets/img/banner3.png";
import banner4 from "../../assets/img/banner4.png";
import sectionTop from "../../assets/img/section_top.png";

const items: any = [banner1, banner2, banner3, banner4];
const Section = () => {
  return (
    <React.Fragment>
      <section className="banner position-relative" id="home">
        <Container>
          <Row className="banner-2-content align-items-center">
            <Swiper
              effect={"fade"}
              slidesPerView={1}
              pagination={{ clickable: true }}
              modules={[EffectFade, Pagination, Autoplay]}
              loop={true}
              autoplay={{ delay: 3000, disableOnInteraction: false }}
              className="banner-swiper"
            >
              {items.map((item: any, key: any) => (
                <SwiperSlide key={key} className="banner-slider">
                  <Image className="img-fluid" src={item} alt="" />
                </SwiperSlide>
              ))}
            </Swiper>
          </Row>
        </Container>
        <Container>
          <Row style={{ marginTop: -1 }}>
            <Image className="img-fluid" src={sectionTop} />
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Section;
