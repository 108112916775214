export const termsOfService = [
  {
    "text": "The terms of this agreement (“Terms of Service”) govern the relationship between you and Gear Games, Inc. (together with its subsidiaries, parent companies, joint ventures, and other corporate entities under common ownership and/or any of their agents, consultants, employees, officers, and directors, “Gear Games” or “we” or “us”) regarding your use of Gear Games’ games, websites and any related services (collectively referred to as our “Service”).",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": " ",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "When you access our Service, you may be required to register an account (“Account”). By registering for an Account or by using our Service in any capacity, you represent that you are at least 13 years old (or older) and you understand and agree to these Terms of Service. If you are under the age of 18, you represent that your parents or your legal guardian have reviewed and agreed to these Terms of Service. If you access our Service through a third party platform like Apple or Google and/or a social networking site like Facebook, you are obligated to comply with their terms of service in addition to our Terms of Service.",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": " ",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "By accessing our Service, you are also agreeing to Gear Games' Privacy Policy, which is incorporated into these Terms of Service by this reference.",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "Gear Games reserves the right to change, modify, add or remove portions of these Terms of Service and its Privacy Policy at any time by posting the amended versions on our website and within our games.  By continuing to use our Service after we post such amended versions you will be deemed to have accepted such amendments. If you do not agree with any portion of the amendments or changes that have been posted, you must immediately stop using our Service and your license to use our Service will immediately terminate.",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": " ",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "THIS IS A LEGALLY BINDING AGREEMENT BETWEEN YOU AND Gear Games. BY INSTALLING, USING OR OTHERWISE ACCESSING OUR SERVICE, YOU AGREE TO THESE TERMS OF SERVICE. IF YOU DO NOT AGREE WITH ANY OF THE TERMS OF SERVICE, PLEASE DO NOT INSTALL, USE OR OTHERWISE ACCESS OUR SERVICE. IF YOU HAVE INSTALLED OUR SERVICE, PLEASE UNINSTALL IT IMMEDIATELY. USE OF OUR SERVICE IS VOID WHERE PROHIBITED.",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": " ",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "IMPORTANT NOTICE: FOR U.S. AND CANADIAN PLAYERS, DISPUTES ABOUT THESE TERMS OR RELATING TO OUR SERVICE GENERALLY MUST BE RESOLVED BY BINDING ARBITRATION AND ON AN INDIVIDUAL BASIS ONLY. FOR MORE DETAILS, GO TO SECTION (DISPUTE RESOLUTION, LAW, FORCE MAJEURE AND EQUITABLE REMEDIES).",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "\n ",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "LIMITED LICENSE",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "Grant of a Limited License to Use our Service",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "Subject to your agreement and your continued compliance with these Terms of Service and all relevant Gear Games policies, we grant you a non-exclusive, non-transferable, non-sublicensable, revocable limited license (subject to the limitations below) to access and use our Service for your own personal (i.e. non-commercial) entertainment purposes. You agree not to use our Service for any other purpose.",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "Gear Games reserves the right to determine whether one's conduct violates this Agreement including the intent or spirit of the Terms of Service or our Service itself. Gear Games also reserves the right to take any action it deems appropriate as a result, which may include preventing you access to the Service, terminating your account and/or limiting your Account access.",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": " ",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "You specifically agree to each of the following restrictions when using our Service:",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "If you are under the age of 18, you shall not create an Account (defined below) using our Service without consent from a parent or legal guardian; such consent is necessary for the use of all our Services if you are an under the age of majority.",
    "fontSize": "14px",
    "isChildOfLi": true
  },
  {
    "text": "You are responsible for all transactions in your Account including but not limited to the use of your credit card and other payment systems such as PayPal.",
    "fontSize": "14px",
    "isChildOfLi": true
  },
  {
    "text": "If you have been previously banned from our Service, you are prohibited from re-accessing any of our good or services, including our Service.  ",
    "fontSize": "14px",
    "isChildOfLi": true
  },
  {
    "text": "You are prohibited from using our Service or your Account for commercial purposes.",
    "fontSize": "14px",
    "isChildOfLi": true
  },
  {
    "text": "You are prohibited from using our Service to advertise, solicit, or transmit any commercial advertisements, which include but are not limited to chain letters, junk, spam (e-mail) or repetitive messaging (both targeted and untargeted) to anyone using our Service.",
    "fontSize": "14px",
    "isChildOfLi": true
  },
  {
    "text": "You are prohibited from creating an Account on someone else’s behalf.",
    "fontSize": "14px",
    "isChildOfLi": true
  },
  {
    "text": "You are prohibited from creating an Account using inaccurate or false information, which includes impersonating another.",
    "fontSize": "14px",
    "isChildOfLi": true
  },
  {
    "text": "You are prohibited from renting, selling, gifting, giving away your Account to anyone else.",
    "fontSize": "14px",
    "isChildOfLi": true
  },
  {
    "text": " ",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "License Limitations",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "Any use of our Service in violation of the license limitations contained in this Section 2 is strictly prohibited, and can result in the immediate termination or revocation of your limited license and may subject you to legal liability.",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": " ",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "By accessing our Service, you agree that under no circumstances will you:",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "Engage in acts that we deem inappropriate and/or to be in conflict with the spirit or intent of the Service.",
    "fontSize": "14px",
    "isChildOfLi": true
  },
  {
    "text": "Engage in acts to gain unauthorized access to our Service, registered Accounts of others, or Servers, computers, or Networks that are connected to our Service and/or used in our Service.",
    "fontSize": "14px",
    "isChildOfLi": true
  },
  {
    "text": "Engage in acts that include but are not limited to circumventing, modifying, attempting to circumvent and/or modify, any of our security, technology, devices or software that belongs to us or is part of our Service.",
    "fontSize": "14px",
    "isChildOfLi": true
  },
  {
    "text": "Engage in abusive, threatening, obscene, defamatory, libelous, racially, religiously, sexually, or otherwise any objectionable or offensive acts that include posting and/or communicating such information through our Service, or by any means.",
    "fontSize": "14px",
    "isChildOfLi": true
  },
  {
    "text": "Post information that contains excessive violence, offensive language and nudity including but not limited to links to such content.",
    "fontSize": "14px",
    "isChildOfLi": true
  },
  {
    "text": "Engage in acts or attempts to abuse, harm, harass, advocate or incite harassment, abuse, and violence towards another person, group, our employee(s) or our Service.",
    "fontSize": "14px",
    "isChildOfLi": true
  },
  {
    "text": "Use our Service to post, supply or make available any materials or information that infringes on any copyright, trademark, patent, trade secret, right of privacy, right of publicity, or any other rights of a person or entity.",
    "fontSize": "14px",
    "isChildOfLi": true
  },
  {
    "text": "Use of our Service to post anyone’s private information, including personally identifiable and financial information in any form or medium using our Service.",
    "fontSize": "14px",
    "isChildOfLi": true
  },
  {
    "text": "Conspire or engage in acts that reverse engineer, decompile, disassemble, decipher, or otherwise attempt to derive our source code of any of our Service, intellectual property, or trade secrets used to provide our Service.",
    "fontSize": "14px",
    "isChildOfLi": true
  },
  {
    "text": "Obtain advantage or information from our Service using methods not permitted by Gear Games.",
    "fontSize": "14px",
    "isChildOfLi": true
  },
  {
    "text": "Engage in solicitation or attempted solicitation of personal information from other users of our Service.",
    "fontSize": "14px",
    "isChildOfLi": true
  },
  {
    "text": "Engage in acts that disrupt, overburden, or aid or assist in the disruption or overloading and/or burdening of any of our systems which include but are not limited to computers, servers, phone systems and any other system of service we use in conducting our business and used to provide you our Service.",
    "fontSize": "14px",
    "isChildOfLi": true
  },
  {
    "text": "Engage in the institution, assistance or become involved in any form of attacks, including but not limited to denial of service attacks upon our Service, creation and distribution of virus or malware, or make attempts to disrupt our Service or a persons enjoyment of our Service.",
    "fontSize": "14px",
    "isChildOfLi": true
  },
  {
    "text": "Use exploits, cheats, automation bots, software, hacks, mods or any unauthorized third-party software which is designed to interfere, alter or modify our Service, or that can be used in conjunction with our Service including but not limited to our Games and experiences.",
    "fontSize": "14px",
    "isChildOfLi": true
  },
  {
    "text": "Modify our files, games and/or our Service without our authorization and prior written consent.",
    "fontSize": "14px",
    "isChildOfLi": true
  },
  {
    "text": "Impersonate another person, including without limitation any other user or any Gear Games employee",
    "fontSize": "14px",
    "isChildOfLi": true
  },
  {
    "text": "Login Information and Your Account",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "When you create an Account with us, you may be required to create a username and password (collectively known as “Login Information”). You agree to not share this information with anyone. Such disclosure may result in a violation of this Agreement. You agree not to engage in any acts that may compromise the integrity and/or security of your Account and information. Such compromise may include but is not limited to unauthorized access, loss and theft. If become aware or suspect that your account has been compromised, please contact us immediately. We also urge you to modify your Login Information to prevent further damage.",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": " ",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "It is your sole responsibility for maintaining the confidentiality of information you provide us including your Login Information. You are also responsible for all uses of your Account, all purchases made (within the application and/or otherwise). All Account related activity and actions are solely your responsibility.",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": " ",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "We reserve the right to reclaim and/or remove any usernames at our discretion at any time, for any reason. You agree to only have one Account per game on a properly supported device.",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": " ",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "Suspension and Termination of Account and Service",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "WITHOUT LIMITATION TO ANY OTHER REMEDIES, Gear Games MAY SUSPEND, TERMINATE, DELETE, LIMIT, MODIFY ACCOUNTS OR ACCESS TO THE SERVICES OR ANY PORTIONS THEREOF, IF Gear Games SUSPECTS OR IF YOU HAVE FAILED TO COMPLY WITH ANY OF THE TERMS OF SERVICE, FOR ANY SUSPECTED OR ACTUAL ILLEGAL ACTIVITY, IMPROPER USE OF OUR SERVICES; WITH OR WITHOUT ANY NOTICE TO YOU. SUCH ACTIONS MAY RESULT IN THE LOSS OF YOUR ACCOUNT, INFORMATION, PERSONA, BENEFITS, AND IN GAME PURCHASES. Gear Games IS NOT AND WILL NOT BE HELD RESPONSIBLE FOR ANY INFORMATION, PERSONA, RANKINGS, BENEFITS AND LOSSES INCURRED, AND ARE UNDER NO OBLIGATION TO COMPENSATE YOU FOR ANY OF THIS.",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": " ",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "WE MAY STOP OFFERING AND/OR SUPPORTING OUR SERVICE INCLUDING BUT NOT LIMITED TO ANY PARTICULAR GAME OR PART OF OUR SERVICE AT ANY TIME, AND WE RESERVE THE RIGHT TO DO SO. IF THIS DOES OCCUR, YOUR LICENSE TO USE OUR SERVICE OR APPLICABLE GAME WILL AUTOMATICALLY TERMINATE. WE ARE NOT RESPONSIBLE OR REQUIRED TO NOTIFY YOU OF THIS, OR PROVIDE YOU WITH REFUNDS, COMPENSATION OR ANY MATERIAL OR NON-MATERIAL BENEFIT TO ANY OF OUR USERS FOR DISCONTINUED OR TERMINATED SERVICE. SUCH ACTIONS MAY ALSO RESULT IN TERMINATION OF YOUR ACCOUNT, DISABLING OF YOUR ACCESS TO THE SERVICE, OR ANY PART THEREOF INCLUDING ANY CONTENT THAT MAY HAVE OR WERE IN THE PROCESS OF SUBMITTING.",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": " ",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "WE MAY AT OUR DISCRETION LIMIT, TERMINATE OR SUSPEND YOUR ACCESS TO OUR SERVICE, ACCOUNTS OR ANY PORTION OF OUR SERVICE WE DEEM APPROPRIATE. WE MAY PROHIBIT ANY FUTURE ACCESS TO OUR SERVICE, DELAY OR HOLD, DELAY OR REMOVE HOSTED CONTENT, TAKE LEGAL AND TECHNICAL STEPS TO PREVENT ACCESS TO OUR SERVICE. WE MAY ALSO ENGAGE IN SUCH ACTIONS IF IT IS DEEMED AT OUR SOLE DISCRETION, THAT THERE IS A RISK OR CREATION THEREOF, OR POSSIBLE LEGAL LIABILITIES, INFRINGEMENT OF INTELLECTUAL PROPERTY RIGHTS OF OURS OR A THIRD PARTY, OR ACTING INCONSISTENTLY WITH THE LETTER OR SPIRIT OF OUR TERMS OR POLICIES. Gear Games RESERVES THE RIGHT TO TERMINATE ANY ACCOUNT THAT HAS BEEN INACTIVE FOR 180 DAYS OR MORE.",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": " ",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "You may choose to close your Account for any reason and at any time. If you so choose to exercise this right, please inform Gear Games that you wish to close your account by either: (i) opening a help ticket directly in-game via the help section and requesting your account be closed; or (ii) emailing Gear Games at hello@geargames.com and requesting your account be closed. Be sure to provide your email address in the request, so we have the information necessary to close your account.",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": " ",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": " ",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "OWNERSHIP",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "Our Service",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "Gear Games owns all rights, title, and interest in and to our Service. This may include without limitation all games, software (including server software), titles, characters, character names, stories, dialogue, animations, art, concepts, audio sounds and effects, musical compositions, visual effects, methods of operation, documentation, moral rights, in-game chat transcripts, character profiles, all recordings of games and game play. We reserve all rights, without any limitation to all intellectual property and proprietary rights, in connection with our Service.",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": " ",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "Accounts",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "You acknowledge and agree that you will have no ownership or other property interest in the Account. You further acknowledge and agree that all interest and rights to the Account are and will be owned in perpetuity by Gear Games, and only for the benefit of Gear Games.",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": " ",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "Virtual Items",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "Gear Games owns, has licensed, or otherwise has rights to use all of the content that appears in our Service. By accessing and using our Service, you agree that you have no rights or title in or to any content, information, or attributes that appears in our Service. This encompasses virtual items, goods, and currency appearing and/or originating in our Service, whether it is provided, earned or purchased from Gear Games.  ",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "\n ",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "USER CONTENT",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "By using our Service, you agree that all User Content along with personal information may be processed by us in accordance with our Privacy Policy. Gear Games reserves the right at its own discretion to review, monitor, prohibit, edit, delete, and disable access to your User Content without any prior notice at any time for any reason. Please contact us if you have questions regarding your User Content or Account by either: (i) opening a help ticket directly in-game via the help section; or (ii) emailing Gear Games at hello@geargames.com.",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": " ",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "“User Content” means all materials, data, information, communication including chatting, pictures and sounds that you or other users upload and/or transmit using our Service. When you submit and/or transmit any User Content when using our Service, you represent, warrant and affirm that such content is accurate, non-confidential, does not violate any state or federal laws or rights of others, that you have the appropriate permissions or right from third parties whose information or intellectual property is comprised in the User Content; that such User Content is free of malware, viruses, adware, spyware or any malicious code.",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": " ",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "Content Monitoring",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "Gear Games will not assume any liability or responsibility for any user behavior, or for monitoring User Content or inappropriate conduct on our Service. User Content submitted on, or to, our Service is not screened or monitored by us. The users of our Service bear all risk associated with its use including but not limited to exposure to offensive and indecent content. Gear Games at its discretion may monitor and/or record your interactions with our Service including all communications and information that is transmitted. By using and accessing our Service, you provide Gear Games with an irrevocable consent to such actions. If we at our own discretion choose to monitor our Service, we assume no liability or obligation to change, remove, or disable any inappropriate User Content; we retain the right to such actions without any obligation to do so. You understand and agree that there is no expectation of privacy to your User Content when using our Service.",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": " ",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "Public Forums",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "We provide forums, blogs and chat features in our Service, where users can post information. Users may post topics of interest, general information, comments, and sometimes even personal information in these mediums. You understand that Gear Games is not responsible for any information posted in, or on, our Service or whether others will view your information, comments and topics in the same light. Please do not use these mediums to share or post information if you wish for such information to remain confidential; do not submit, upload, write, or transmit any information on our Service you deem to be confidential. Gear Games does not and will not bear any responsibility or liability of the use of any such information; no compensation will be provided for any ideas, schemes, uses of any information provided.",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": " ",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "Responsibility For Your Own Content",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "As a user, you are personally and solely responsible for all information posted and/or sent, transmitted or provided to others in our Service. We reserve the right to reject, deny and delete any User Content with or without any reason.",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": " ",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "User Content License",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "You hereby grant to Gear Games an irrevocable, perpetual, transferable, fully paid-up, royalty-free, worldwide license (including the right to sublicense and assign to third party) and right to copy, reproduce, fix, adapt, modify, create derivative works from, manufacture, commercialize, publish, distribute, sell, license, sublicense, transfer, lease, transmit, publicly display, publicly perform, or provide access to electronically, broadcast, communicate to the public by telecommunication, display, perform, enter into computer memory, and use and practice, in any way, your User Content as well as all modified and derivative works thereof in connection with our provision of our Service, including marketing and promotions of our Service.",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "You also hereby grant to Gear Games the right to authorize others to exercise any of the rights granted to Gear Games under these Terms of Service. You further hereby grant to Gear Games the unconditional, irrevocable right to use and exploit your name, likeness and any other information or material included in any User Content and in connection with any User Content, without any obligation to you. Except as prohibited by law, you waive any rights of attribution and/or any moral rights you may have in your User Content, regardless of whether your User Content is altered or changed in any manner.",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": " ",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "Gear Games does not claim any ownership rights in your User Content and nothing in these Terms of Service is intended to restrict any rights that you may have to use and exploit your User Content. Gear Games has no obligation to monitor or enforce your intellectual property rights in or to your User Content.",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": " ",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "User Interactions",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "You are solely responsible for your interactions with other users of our Service and any other parties with whom you interact through our Service. Gear Games reserves the right, but has no obligation, to become involved in any way with these disputes. You will fully cooperate with Gear Games to investigate any suspected unlawful, fraudulent or improper activity, including, without limitation, granting Gear Games access to any password-protected portions of your Account as may be required.",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": " ",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "If you have a dispute with one or more users, you release us (and our officers, directors, agents, subsidiaries, joint ventures and employees) from claims, demands and damages (actual and consequential) of every kind and nature, known and unknown, arising out of or in any way connected with such disputes.",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": " ",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "FEES AND PURCHASE TERMS",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "Virtual Items",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "Any time you purchase virtual currency (including but not limited to virtual cash and other in-game premium currency), virtual in-game items, and any other premium goods or services in our Service with real currency (i.e., real world money) (collectively, “Virtual Items”), Gear Games grants you a limited, personal, non-transferable, non-sublicensable, revocable license to use such Virtual Items. All purchases and redemptions of such Virtual Items through our Service are final and non-refundable. Gear Games may manage, regulate, control, modify or eliminate Virtual Items at any time, with or without notice to you. Gear Games will have no liability to you or any third party in the event that Gear Games exercises any such rights.",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": " ",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "The transfer of Virtual Items is prohibited except where expressly authorized in our Service. Other than as expressly authorized in our Service, you may not sell, redeem or otherwise transfer Virtual Items to any person or entity, including but not limited to Gear Games, another user or any third party.",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": " ",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "Payment of Fees",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "You agree to pay all fees and applicable taxes incurred by you or anyone using an Account registered to you. Gear Games may revise the pricing for the goods and services offered through our Service at any time. You acknowledge and agree that Gear Games is under no obligation to provide you with a refund for any reason including but not limited to termination of your Account, when closing your Account whether such actions are voluntary or involuntary; for any items of value or otherwise (including virtual currency) that are in your Account whether purchased, accrued or given.",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "\n ",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "UPDATES TO OUR SERVICE",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "You understand that our Service is a developing and evolving one. Gear Games may require that you accept updates to our Service. While Gear Games will make all reasonable efforts to inform you of any such updates, you acknowledge and agree that Gear Games may update our Service, with or without informing or otherwise notifying you. You may need to update third party software from time to time in order to receive our Service and/or play our games.",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "\n ",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "DISCLAIMER OF WARRANTIES",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "Without limiting Gear Games' liability under the Section below, our Service is provided on an “as is” and “as available” basis for your use, with no warranties of any kind, express or implied, including without limitation the warranties of merchantability, fitness for a particular purpose, title, non-infringement, and those arising from course of dealing or usage of trade. Gear Games does not warrant that you will be able to access or use our Service at the times or locations of your choosing; that our Service will be uninterrupted or error-free; that defects will be corrected; or that our Service is free of viruses or other harmful components.",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "\n ",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "LIMITATION OF LIABILITY AND INDEMNIFICATION",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "Limitation of Liability",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "Gear Games will not be liable to you for any indirect, incidental, consequential, special, punitive or other similar damages, including but not limited to loss of revenues, lost profits, lost data or business interruption or other intangible losses (however such losses are qualified), arising out of or relating in any way to these Terms of Service or our Service itself, whether based on contract, tort or any other legal theory, and whether or not Gear Games has been advised of the possibility of such damages. Gear Games will not be liable to you for more than the amount you have paid to Gear Games in accordance with these terms of service in the six (6) months immediately preceding the date on which you first assert a claim. You acknowledge and agree that if you have not paid anything to Gear Games during such time period, your sole remedy (and Gear Games' exclusive liability) for any dispute with Gear Games is to stop using our Service and to cancel your Account.",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": " ",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "Some jurisdictions do not allow the exclusion of certain warranties or the limitation or exclusion of liability for certain types of damages. Accordingly, some of the above disclaimers and limitations may not apply to you. To the extent that Gear Games may not, as a matter of applicable law, disclaim any warranty or limit its liability as set forth herein, the scope of such warranty and the extent of Gear Games' liability will be the minimum permitted under such applicable law.",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": " ",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "In particular, nothing in these Terms of Service will affect the statutory rights of any consumer or exclude or restrict any liability for death or personal injury arising from any negligence or fraud of Gear Games.",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": " ",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "Indemnification",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "You agree to indemnify, save, and hold Gear Games, its affiliated companies, contractors, employees, agents and its third-party suppliers, licensors, and partners harmless from any claims, losses, damages, liabilities, including legal fees and expenses, arising out of your use or misuse of our Service, any violation by you of these Terms of Service, or any breach of the representations, warranties, and covenants made by you herein. Gear Games reserves the right, at your expense, to assume the exclusive defense and control of any matter for which you are required to indemnify Gear Games, and you agree to cooperate with Gear Games' defense of these claims. Gear Games will use reasonable efforts to notify you of any such claim, action, or proceeding upon becoming aware of it.",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "You agree that the provisions in this paragraph will survive any termination of your Account(s) or of our Service.",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "\n ",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "DISPUTE RESOLUTION, LAW, FORCE MAJEURE AND EQUITABLE REMEDIES",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "If a dispute arises between you and Gear Games, we prefer amicable resolution to protracted legal battles. Toward that end, we have created a dispute resolution program we believe can resolve most matters. In order to avail yourself of our arbitration policy, you must first exhaust our steps to resolution:",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "1. CHECK THE FAQ: We recommend you start your resolution process by reviewing our “help FAQ” section for the applicable game you are playing. Each “FAQ” section is specific to each game. This section often addresses the most commonly asked questions or concerns players of our games may have. Start here.",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": " ",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "2. IN-GAME HELP TICKET: If our FAQ did not resolve your issues, you may complete a help ticket in-game. Please state clearly your issue. Generally, a help agent will get back to you through the inbox message system in your account. Please bear with us; we may need to research your issue to fully resolve your problem. This may require us to ask you further questions. Our help agents will work with you to resolve your issue. If they cannot resolve it, they will escalate it to our help managers.",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": " ",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "3. HELP MANAGERS: Although most issues are quickly resolved using our FAQ or in-game help ticket process, certain issues may require management assistance. If that is the case, your help ticket will be transferred to our help managers. These managers have the authority and experience to address the vast majority of issues that may arise. In some cases, our help managers can fix the problem remotely. Sometimes we may ask to speak with you directly and will arrange a mutual time to discuss your matter. If you provide us with your phone number for this purpose, it will not be used for other purposes.",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": " ",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "4. In the event our FAQ, our help agents, and our help managers are unable to resolve your issue, we may ask you to speak with Gear Games management, or we may advise you of your other options, outlined here:",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": " ",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "IF YOU ARE A RESIDENT OF THE UNITED STATES OR CANADA, YOU AND Gear Games ALL AGREE TO RESOLVE ANY DISPUTE ARISING OUT OF OR RELATED TO THIS TERMS OF SERVICE, THE PRIVACY POLICY OR OUR SERVICE THAT CANNOT BE RESOLVED THROUGH OUR DISPUTE SYSTEM, WHICH HAS BEEN EXHAUSTED, THROUGH FINAL AND BINDING ARBITRATION. THIS APPLIES TO ALL KINDS OF CLAIMS UNDER ANY LEGAL THEORY UNLESS THE CLAIM FITS IN ONE OF THE EXCEPTIONS IN THE EXCEPTIONS TO AGREEMENT TO ARBITRATE SUB-SECTION. IT ALSO APPLIES EVEN AFTER YOU STOPPED USING YOUR Gear Games ACCOUNT OR HAVE DELETED IT.",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": " ",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "An arbitration proceeding is before a neutral arbitrator instead of a judge and jury, so we both agree to give up our right to a trial before a judge and jury. Arbitrations have different rules than lawsuits in court. They are less formal than lawsuits in courts, and provide limited opportunity to force the other side to share information relevant to the dispute—a process called discovery. The arbitrator can award the same damages and relief on an individual basis that a court can award to an individual. But, if any of us does not like the arbitrator’s decision, the courts only have a limited ability to change the outcome of arbitration or make the arbitrator reconsider his or her decision.",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": " ",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "If we have a dispute about whether this agreement to arbitrate can be enforced or applies to our dispute, we all agree that the arbitrator will decide that, too.",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": " ",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "Exceptions to Agreement to Arbitrate",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "We all agree that we will go to court to resolve disputes relating to:",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "Your or Gear Games' intellectual property (for example, trademarks, trade dress, domain names, trade secrets, copyrights or patents); or",
    "fontSize": "14px",
    "isChildOfLi": true
  },
  {
    "text": "Any claim made in small claims court either in Los Angeles, California, or the county where you live, or some other place we both agree on, if it qualifies to be brought in that court.",
    "fontSize": "14px",
    "isChildOfLi": true
  },
  {
    "text": " ",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "No Class Actions",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "We all agree that we can only bring a claim against each other on an individual basis.",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "That means:",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "Neither you nor Gear Games can bring a claim as a plaintiff or class member in a class action, consolidated action or representative action.",
    "fontSize": "14px",
    "isChildOfLi": true
  },
  {
    "text": "The arbitrator cannot combine more than one person’s claim into a single case, and cannot preside over any consolidated, class or representative arbitration proceeding (unless we both agree to change this).",
    "fontSize": "14px",
    "isChildOfLi": true
  },
  {
    "text": "The arbitrator’s decision or award in one person’s case can only impact the person who brought the claim, not other users of our Service, and cannot be used to decide other disputes with other users.",
    "fontSize": "14px",
    "isChildOfLi": true
  },
  {
    "text": " ",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "Applicable Law",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "The Federal Arbitration Act (including its procedural provisions) is the law that will be applied to determine whether the dispute resolution provisions in Section (DISPUTE RESOLUTION, LAW, FORCE MAJEURE AND EQUITABLE REMEDIES) regarding arbitration and class action can be enforced and how they should be interpreted.",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": " ",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "Apart from that, if you are United States resident, these Terms of Service and our relationship will be governed by California law, except for its conflicts of laws principles. If you reside outside of the United States, these Terms of Service and our relationship will be governed by English law, except for its conflicts of laws principles.",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": " ",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "Force Majeure",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "Gear Games will not be liable for any delay or failure to perform resulting from causes outside the reasonable control of Gear Games, including without limitation any failure to perform hereunder due to unforeseen circumstances or cause such as acts of God, war, terrorism, riots, embargoes, acts of civil or military authorities, fire, floods, accidents, strikes, or shortages of transportation facilities, fuel, energy, labor or materials.",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": " ",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "Equitable Remedies",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "You acknowledge that the rights granted and obligations made under these Terms of Service to Gear Games are of a unique and irreplaceable nature, the loss of which will irreparably harm Gear Games and which cannot be replaced by monetary damages alone so that Gear Games will be entitled to injunctive or other equitable relief (without the obligations of posting any bond or surety or proof of damages) in the event of any breach or anticipatory breach by you.",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": " ",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "You irrevocably waive all rights to seek injunctive or other equitable relief, or to enjoin or restrain the operation of our Service, exploitation of any advertising or other materials issued in connection therewith, or exploitation of our Service or any content or other material used or displayed through our Service and agree to limit your claims to claims for monetary damages, limited by Section (LIMITATION OF LIABILITY AND INDEMNIFICATION) - (if any).",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "\n ",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "SEVERABILITY",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "You and Gear Games agree that if any portion of these Terms of Service or of the Gear Games Privacy Policy is found illegal or unenforceable, in whole or in part by any court of competent jurisdiction, such provision will, as to such jurisdiction, be ineffective solely to the extent of such determination of invalidity or unenforceability without affecting the validity or enforceability thereof in any other manner or jurisdiction and without affecting the remaining provisions of the terms, which will continue to be in full force and effect.",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "\n ",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "GENERAL PROVISIONS",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "Assignment",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "Gear Games may assign or delegate these Terms of Service and/or the Gear Games Privacy Policy, in whole or in part, to any person or entity at any time with or without your consent. You may not assign and/or delegate any of the rights or obligations you have under the Terms of Service and/or the Privacy Policy unless you obtain Gear Games' prior written consent.  Any such assignments and/or delegations by you without Gear Games' prior written approvals are ineffective and in violation of these Terms of Service.",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": " ",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "Supplemental Policies",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "Gear Games may publish additional policies related to specific features such as forums, contests or loyalty programs. Your right to use such features is subject to those specific policies (if any) and these Terms of Service.",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": " ",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "Entire Agreement",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "These Terms of Service including any additional policies and documents referenced in this agreement (such as Gear Games' Privacy Policy) are incorporated by this reference. They supersede all prior understandings between Gear Games and you; regardless of the medium (oral, written electronic) and practice (custom, policy, course of business, precedent) such understandings were communicated.",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": " ",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "No Waiver",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "The failure of Gear Games to require or enforce strict performance by you of any provision of these Terms of Service or the Gear Games Privacy Policy or failure to exercise any right under them will not be construed as a waiver or relinquishment of Gear Games' right to assert or rely upon any such provision or right in that or any other instance.",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": " ",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "The express waiver by Gear Games of any provision, condition, or requirement of these Terms of Service or the Gear Games Privacy Policy will not constitute a waiver of any future obligation to comply with such provision, condition or requirement.",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": " ",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "Except as expressly and specifically set forth in this these Terms of Service, no representations, statements, consents, waivers, or other acts or omissions by Gear Games will be deemed a modification of these Terms of Service nor legally binding, unless documented in physical writing, hand signed by You and a duly appointed officer of Gear Games.",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": " ",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "Notices",
    "fontSize": "14px",
    "isChildOfLi": false
  },
  {
    "text": "We may notify you via postings on https://geargames.com, and via e-mail or any other communications means to contact information you provide to us. All notices given by you or required from you under these Terms of Service or the Gear Games Privacy Policy must be in writing and addressed to: GEAR GAMES INC. 251 LITTLE FALLS DR WILMINGTON, DE 19808. Any notices that you provide without compliance with this Section on Notices will have no legal effect.",
    "fontSize": "14px",
    "isChildOfLi": false
  },
]