import React from "react";
import { publicroute, routes } from "./allRoutes";
import { Route, Routes } from "react-router-dom";
import Layout from "../Layout";
import Nonauthlayout from "./nonauthlayout";

const Routing = () => {
  return (
    <React.Fragment>
      <Routes>
        <Route>
          {publicroute.map((route: any, idx: any) => (
            <Route path={route.path} element={<Nonauthlayout>{route.component}</Nonauthlayout>} key={idx} />
          ))}
        </Route>
        <Route>
          {routes.map((route: any, idx: any) => (
            <Route path={route.path} element={<Layout isDark={route.isDark}>{route.component}</Layout>} key={idx} />
          ))}
        </Route>
      </Routes>
    </React.Fragment>
  );
};
export default Routing;
