import React from "react";
import AboutUs from "../components/AboutUs";
import Contact from "../components/Contact";
import Navbars from "./Navbar";
import Games from "../components/Games";
import Play from "../components/Play";
import Services from "../components/Services";
import Partners from "../components/Partners";
import DocumentNav from "../components/DocumentNav";

const Layout = ({ children, isDark }: any) => {
  return (
    <React.Fragment>
      <Navbars isDark={isDark} />
      {children}
      <Games />
      <Play />
      <AboutUs />
      <Services />
      <Partners />
      <Contact />
      <DocumentNav />
    </React.Fragment>
  );
};

export default Layout;
