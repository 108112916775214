import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { termsOfService } from "./termsofservice";
import { getContent } from "../../utils/get-content";

const Section = () => {
  const leftContent = getContent(termsOfService.slice(0, 89));
  const rightContent = getContent(termsOfService.slice(89));
  return (
    <React.Fragment>
      <section id="terms-of-service" className="position-relative document-section">
        <Container>
          <Row className="align-items-center m-0">
            <Row className="content my-4">
              <p className="fs-2">
                Gear Games TERMS OF SERVICE
              </p>
              <p className="fs-4 mb-4">
                PLEASE READ THESE TERMS OF SERVICE CAREFULLY BEFORE USING OUR SERVICES.
              </p>

              <Col xs={12} sm={12} md={12} lg={6}>
                {leftContent}
              </Col>
              <Col xs={12} sm={12} md={12} lg={6}>
                {rightContent}
              </Col>
            </Row>
            <Row className="mb-4 content fs-6">In association with:&#20;<a href="http://directory.askbee.net" id="RBADAA2">Askbee directory</a></Row>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
}

export default Section;