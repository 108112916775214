/** Add Route Component */
import Home from "../pages/home";
import PrivacyPolicy from "../pages/privacypolicy";
import TermsOfService from "../pages/termsofservice";
interface RouteProps {
  path: string;
  component?: any;
  exact?: boolean;
  isDark: boolean;
}

const routes: Array<RouteProps> = [{ path: "/", component: <Home />, isDark: true }];
const publicroute = [
	{path: "/privacypolicy", component: <PrivacyPolicy />},
	{path: "/termsofservice", component: <TermsOfService />},
];
export { publicroute, routes };
