import React from "react";
import { Container, Row, Col, Image, Stack } from "react-bootstrap";

import footerImg from "../assets/img/footer.png";
import logoLight from "../assets/img/logo_light.png";

const Contact = () => {
  const innerWidth = window.innerWidth;
  const mt = innerWidth > 2000 ? 200 : innerWidth > 1200 ? 180 : innerWidth > 900 ? 180 : innerWidth > 600 ? 140 : 100;
  return (
    <React.Fragment>
      <section id="contact">
        <Container>
          <Row
            className=" bg-image d-flex align-items-center"
            style={{ backgroundImage: `url(${footerImg})`, height: mt, backgroundSize: "cover", backgroundPosition: "center" }}
          >
            <Col lg={12} xs={11} className="mt-lg-5 p-lg-5 p-md-3 mt-3 p-1 p-xs-0">
              <Stack direction="horizontal">
                <Image src={logoLight} width={innerWidth > 300 ? 120 : 60} />
                <div className=" ms-auto contact-us text-end">
                  <p className="text-uppercase fw-bold mb-0">Contact Us</p>
                  <a href="mailto:hello@geargames.com">
                    <h4 className="text-uppercase">hello@geargames.com</h4>
                  </a>
                </div>
              </Stack>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Contact;
