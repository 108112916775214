import React from "react";
import { Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const DocumentNav = () => {
  return (
    <React.Fragment>
      <section id="document-nav">
        <Container>
          <Row className="d-flex justify-content-center mt-2 mb-3 content">
            <Link target="_blank" rel="noopener noreferrer" to="/privacypolicy" onClick={() => window.scrollTo(0, 0)} className="px-3 fs-6 fw-bold">
              Privacy Policy
            </Link>
            <Link target="_blank" rel="noopener noreferrer" to="/termsofservice" onClick={() => window.scrollTo(0, 0)} className="px-3 fs-6 fw-bold">
              Terms Of Service
            </Link>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default DocumentNav;