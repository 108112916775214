import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { getContent } from "../../utils/get-content";
import { privacyPolicy } from "./privacypolicy";

const Section = () => {
  const leftContent = getContent(privacyPolicy.slice(0, 23));
  const rightContent = getContent(privacyPolicy.slice(23));
  return (
    <React.Fragment>
      <section id="privacy-policy" className="position-relative document-section">
        <Container>
          <Row className="align-items-center m-0">
            <Row className="content my-4">
              <p className="fs-2">
                Gear Games PRIVACY POLICY
              </p>
              <p className="fs-4 mb-4">
                This privacy policy governs your use of the software applications for mobile and web devices that was created by Gear Games Inc. The Application is a mobile game application, aimed at any ages.
              </p>

              <Col xs={12} sm={12} md={12} lg={6}>
                {leftContent}
              </Col>
              <Col xs={12} sm={12} md={12} lg={6}>
                {rightContent}
              </Col>
            </Row>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
}

export default Section;