import { ReactNode } from "react"

const formattenParagraphs = (content: any) => {
  const emailRegex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/g;
  const urlRegex = /https?:\/\/[^\s]+/g;
  const formRegex = /^[^a-zA-Z0-9]+|[^\w]*$/g;

  const replaceEmailWithLink = (match: string) => `<a href="mailto:${match.replace(formRegex, '')}">${match}</a>`;
  const replaceUrlWithLink = (match: string) => `<a href="${match.replace(formRegex, '')}" target="_blank" rel="noopener noreferrer">${match}</a>`;

  let processedText = content.text.replace(emailRegex, replaceEmailWithLink);
  processedText = processedText.replace(urlRegex, replaceUrlWithLink);

  return <p className={content.fontSize === "14px" ? "fs-6" : "fs-5"} dangerouslySetInnerHTML={{ __html: processedText }}></p>;
};

export const getContent = (contents: any[]) => {
  return contents.reduce((acc: { content: ReactNode[], ul: any[] }, content: any, index: number) => {
    if (content.isChildOfLi) {
      if (index === contents.length - 1) {
        return {
          content: [
            ...acc.content,
            <ul>
              {
                [
                  ...acc.ul,
                  <li>{formattenParagraphs(content)}</li>,
                ]
              }
            </ul>,
          ],
          ul: [],
        };
      }
      return { 
        ...acc,

        ul: [
          ...acc.ul,
          <li>{formattenParagraphs(content)}</li>,
        ],
      };
    } else {
      if (acc.ul.length) 
        return {
          content: [
            ...acc.content,
            <ul>{acc.ul}</ul>,
            formattenParagraphs(content),
          ],
          ul: [],
        };
      return {
        content: [
          ...acc.content,
          formattenParagraphs(content),
        ],
        ul: [],
      };
    }
  }, { content: [], ul: [] }).content; 
}